import PropTypes from 'prop-types';
import React from 'react';
import GridElement from '../GridElement/grid-element';
import styles from './grid-container.styles';

const {
  StyledGridContainer, StyledHeader, StyledTitle, StyledBody,
} = styles;

const GridContainer = (props) => {
  const {
    title, elements, cssRules = '', isfMediaGrid,
  } = props;

  return (
    <StyledGridContainer cssRules={cssRules}>
      <StyledHeader>
        <StyledTitle>{title}</StyledTitle>
      </StyledHeader>
      <StyledBody>
        {elements
          && elements.map((element) => (
            <GridElement element={element} isfMediaGrid={isfMediaGrid} />
          ))}
      </StyledBody>
    </StyledGridContainer>
  );
};

GridContainer.defaultProps = {
  cssRules: '',
  elements: undefined,
};

GridContainer.propTypes = {
  cssRules: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.any),
  title: PropTypes.string.isRequired,
};

export default GridContainer;
