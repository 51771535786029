import styled, { css } from 'styled-components';
import { screen } from '@styles/mixins/breakpoints';
import { breakpoints, fonts, colors } from '@styles/variables';

const StyledGridContainer = styled.div`
  max-width: ${breakpoints.xl};
  padding: 48px 30px;
  margin: 0 auto;

  ${screen.tablet(css`
    max-width: inherit;
  `)}

  ${screen.l(css`
    padding: 0 16px 45px;
  `)}

  ${({ cssRules }) => cssRules}
`;

const StyledHeader = styled.div`
  margin-bottom: 64px;
  text-align: center;

  ${screen.l(css`
    margin-bottom: 45px;
  `)}
`;

const StyledTitle = styled.h1`
  color: ${colors.colorBlack};
  font-family: ${fonts.fontSecondaryBold};
  font-size: 32px;
  line-height: 48px;

  ${screen.l(css`
    font-size: 24px;
  `)}
`;

const StyledBody = styled.div`
  display: grid;
  align-items: flex-start;
  grid-gap: 60px 33px;
  grid-template-columns: repeat(4, 1fr);

  ${screen.tablet(css`
    grid-gap: 47px 16px;
    grid-template-columns: repeat(3, 1fr);
  `)}

  ${screen.l(css`
    grid-template-columns: repeat(2, minmax(calc(50% - 32px), 1fr));
  `)}

  ${screen.sm(css`
    grid-template-columns: 1fr;
  `)}
`;

export default {
  StyledGridContainer,
  StyledHeader,
  StyledTitle,
  StyledBody,
};
