import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import GridContainer from '@components/GridContainer/grid-container';

const Leadership = () => {
  const profiles = useStaticQuery(graphql`{
  allJsonJson {
    nodes {
      profiles {
        list {
          id
          name
          imageUrl {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          jobTitle
          socialNetworks {
            id
            type
            value
          }
          sections {
            id
            type
            value
          }
        }
      }
    }
  }
}
`);

  const profilesData = profiles.allJsonJson.nodes.find(
    ({ profiles }) => !!profiles,
  ).profiles.list;

  const descriptionText = profilesData
    && profilesData[0].sections
      .find(({ type }) => type === 'text')
      .value.slice(0, 160);

  return (
    <>
      <Helmet>
        <meta name="description" content={descriptionText} />
        <meta itemProp="description" content={descriptionText} />
        <meta property="og:description" content={descriptionText} />
        <meta property="twitter:description" content={descriptionText} />
      </Helmet>
      <GridContainer
        title="Executive profiles"
        elements={profilesData}
        cssRules="padding-top: 0;"
      />
    </>
  );
};

export default Leadership;
