import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { screen } from '@styles/mixins/breakpoints';
import { colors, fonts } from '@styles/variables';

const StyledElement = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`;

const StyledImgContainer = styled.div`
  position: relative;
  width: 100%;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    content: '';
    transition: background-color 0.15s;
  }

  &:hover {
    &::after {
      background: rgba(0, 0, 0, 0.25);
    }
  }
`;

const StyledTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px 0 0;
  color: ${colors.colorBlack};

  ${screen.l(css`
    padding: 15px 8px 0 0;
  `)}

  ${screen.sm(css`
    height: inherit;
  `)}
`;

const StyledTitle = styled.h2`
  margin-bottom: 8px;
  font: 20px/30px ${fonts.fontPrimaryBold};
`;

const StyledText = styled.p`
  margin-bottom: 16px;
  font: 14px/21px ${fonts.fontPrimaryRegular};
`;

const StyledLinkToDownload = styled.a`
  cursor: pointer;
  text-decoration: none;
`;

export default {
  StyledElement,
  StyledImgContainer,
  StyledTextContainer,
  StyledTitle,
  StyledText,
  StyledLinkToDownload,
};
