import PropTypes from 'prop-types';
import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";

import ArrowDown from '@icons/download_arrow.svg';

import styles from './grid-element.styles';

const {
  StyledElement,
  StyledImgContainer,
  StyledTextContainer,
  StyledTitle,
  StyledText,
} = styles;

const GridElement = (props) => {
  const { element, isfMediaGrid = false } = props;
  const {
    id,
    name,
    title,
    jobTitle,
    itemCount,
    downloadUrl,
    imageUrl: imagePath,
  } = element;

  const actionProps = {
    ...(Boolean(!downloadUrl) && { to: `/who-we-are/leadership/${id}/` }),
    ...(Boolean(downloadUrl) && { download: title }),
    ...(Boolean(downloadUrl) && { href: downloadUrl }),
  };

  return (
    <StyledElement {...actionProps}>
      <StyledImgContainer>
        <GatsbyImage
          image={imagePath?.childImageSharp?.gatsbyImageData}
          objectFit="cover"
          objectPosition="center top"
          loading="lazy"
          title={title}
          alt={id}
          backgroundColor="#EEEEEE"
          imgStyle={{ objectPosition: 'center top' }}
          style={{
            width: '100%',
            height: isfMediaGrid ? '300px' : '400px',
          }} />
      </StyledImgContainer>

      <StyledTextContainer>
        <div>
          <StyledTitle>{name || title}</StyledTitle>
          <StyledText>{jobTitle || `${itemCount} Files`}</StyledText>
        </div>
        {itemCount && (
          <div>
            <ArrowDown />
          </div>
        )}
      </StyledTextContainer>
    </StyledElement>
  );
};

GridElement.propTypes = {
  element: PropTypes.shape({
    downloadUrl: PropTypes.string,
    id: PropTypes.string,
    imageUrl: PropTypes.string,
    itemCount: PropTypes.number,
    jobTitle: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default GridElement;
